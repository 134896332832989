@use '@angular/material' as mat;
@import "bootstrap/dist/css/bootstrap.css";
@import "theme.scss";


@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

:root{
    --primary-light: #066d3e;
//var(--primary-light): #00a19a;
    --main-light: #7DBC81;
    --main-dark: #044208;
    --contrast: #245D60;
    --contrast-light: #608E90;
    --contrast-dark: #033032;
    --danger: #9F3D3A;
    --danger-light: #EFA19F;
    --danger-dark: #530604;
    --gross: #9F693A;
}


html{
    height: 100%;
}

body{
    background-color: $my-app-bg;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    height: 100%;
    
}

div.background{
    z-index: -1;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-image: url(/assets/images/login_background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.btn-link {
    color: map-get(mat.$m2-green-palette, 900);
    text-decoration: none;
    text-align: left;
    padding: 0;
    border: none;
    background-color: transparent;
    width: 100%;

    &:hover {
        color: map-get(mat.$m2-green-palette, 400);
    }
}


button.mat-mdc-unelevated-button.mat-primary, button.mat-mdc-raised-button.mat-primary{
    background-color: var(--primary-light) !important;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(255, 255, 255, .2);
}

::-webkit-scrollbar-thumb {
    background: rgba(6,109,62, .3); 
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(6,109,62, 1); 
}

.no-gutter {
    --bs-gutter-x: 0;
}

.cdk-overlay-dark-backdrop {
    background-color: rgba(0,0,0,.8);
}

.cdk-overlay-container {
    z-index: 5000;
}

iframe.threeDs {
    position: fixed;

    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: none;

    @media only screen and (min-width: 600px) and (min-height: 500px) {
        max-width: 400px;
        width: 100%;
        height: 650px;
        min-height: 500px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
    }

    &::before {
        display: block;
        background-color: rgba(0, 0, 0, 0.6);
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    background-color: white;
    z-index: 1000;
}

.not-mobile{
    display: none;
}

@media only screen and (min-width: 600px) {
    .not-mobile{
        display: initial;
    }
    
}

div.call-to-action{
    display: flex;
    align-items: center;
    div{
        font-size:  calc(1.2rem + 0.6vw);
        font-weight: 500;
        line-height: 1.2;
        color: var(--bs-heading-color);
    }
}

.inner-container{
    padding: 0 1rem;
}


button{
    line-height: normal !important;
  }


.action-box{
    background-color: white;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: .3rem;
    padding: .5rem;
    margin-bottom: 1rem;
    cursor: pointer;

}